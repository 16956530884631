import React, { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import diamond from '../assets/personal-brand-25px.png';
import "./Navbar.css";

function NavbarComponent() {
  let element;
  let sticky;
  let sections;

  useEffect(() => {
    element = document.getElementById("nav-container");
    sticky = element.offsetTop;
    sections = [
      document.getElementById("about"),
      document.getElementById("skills"),
      document.getElementById("experience"),
      document.getElementById("work"),
      document.getElementById("contact"),
    ];
    manageNavs(element);
  }, []);

  function manageNavs(element) {
    let navs = element.getElementsByClassName("nav-link");
    for (let i = 0; i < sections.length; i++) {
      let elementTop =
        window.pageYOffset + sections[i].getBoundingClientRect().top;
      let elementBottom = elementTop + sections[i].offsetHeight;
      let windowMiddle = window.scrollY + window.innerHeight / 2;
      if (windowMiddle > elementTop && windowMiddle < elementBottom) {
        navs[i].classList.add("located");
      } else {
        navs[i].classList.remove("located");
      }
    }
  }

  document.addEventListener("scroll", function (e) {
    if (element != undefined) {
      if (window.pageYOffset > sticky) {
        element.classList.add("scrolled");
      } else {
        element.classList.remove("scrolled");
      }
      manageNavs(element);
    }
  });

  return (
    <Navbar
      id="nav-container"
      fixed="top"
      collapseOnSelect
      expand="lg"
      className="custom-nav"
      variant="dark"
    >
      <Navbar.Brand href="#home" className="mr-auto brand">
        <img src={diamond} className='brand-logo' />
        Danny Fung
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link className="nav-link" href="#about">
            About
          </Nav.Link>
          <Nav.Link className="nav-link" href="#skills">
            Skills
          </Nav.Link>
          <Nav.Link className="nav-link" href="#experience">
            Experience
          </Nav.Link>
          <Nav.Link className="nav-link" href="#work">
            Work
          </Nav.Link>
          <Nav.Link className="nav-link" href="#contact">
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarComponent;
