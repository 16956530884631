import React, { useState } from "react";
import { Row, Col, Form, Spinner, Alert } from "react-bootstrap";
import "./ContactForm.css";

function ContactForm() {
  const [data, setData] = useState({ name: "", email: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mailSuccesses, setMailSuccesses] = useState({});

  // const isEmpty = (string) => {
  //   return string.trim() === "";
  // };

  // const isEmail = () => {
  //   const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return data.email.match(regEx);
  // };

  // const inputIsValid = () => {
  //   let errs = {};
  //   if (isEmpty(data.name)) {
  //     errs.name = "Put in your name";
  //   }
  //   if (isEmpty(data.email)) {
  //     errs.email = "Put in your email";
  //   } else if (!isEmail()) {
  //     errs.email = "Invalid email";
  //   }
  //   if (isEmpty(data.message)) {
  //     errs.message = "Write something";
  //   }
  //   return {
  //     errs,
  //     valid: Object.keys(errs).length === 0,
  //   };
  // };

  const resetFields = () => {
    setErrors({});
    setData({ name: "", email: "", message: "" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const { errs, valid } = inputIsValid();
    const formElements = [...e.currentTarget.elements];
    const isValid =
      formElements.filter((elem) => elem.name === "bot-field")[0].value === "";
    const validFormElements = isValid ? formElements : [];
    if (validFormElements.length < 1) {
      setErrors({ ...errors, general: "Invalid fields." });
    } else {
      setLoading(true);
      setErrors({});
      setMailSuccesses({});
      const filledOutElements = validFormElements
        .filter((elem) => !!elem.value)
        .map(
          (element) =>
            encodeURIComponent(element.name) +
            "=" +
            encodeURIComponent(element.value)
        )
        .join("&");
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: filledOutElements,
      })
        .then(() => {
          setLoading(false);
          setMailSuccesses({ general: "Message sent!" });
          resetFields();
        })
        .catch((err) => {
          setLoading(false);
          setErrors({ ...errors, general: "Internal server error." });
          console.log(err);
        });
    }
  };

  return (
    <Row className="contact-form">
      <Col md={6}>
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={(e) => handleSubmit(e)}
        >
          <p style={{ display: "none" }}>
            <input name="bot-field" />
          </p>
          <input
            type="hidden"
            name="form-name"
            value="contact"
            read-only="true"
          />
          <p>
            <input
              type="text"
              placeholder="Your name"
              name="name"
              onChange={handleChange}
              value={data.name}
              required
              className='custom-input'
            />
          </p>
          <p>
            <input
              type="email"
              placeholder="Your email"
              name="email"
              onChange={handleChange}
              value={data.email}
              required
              className='custom-input'
            />
          </p>
          <p>
            <textarea
              placeholder="Say Hi!"
              name="message"
              onChange={handleChange}
              value={data.message}
              rows={5}
              required
              className='custom-input'
            />
          </p>
          <button
            className="highlight-button highlight-black"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" className="spinner" />
            ) : (
              <h5>Submit</h5>
            )}
          </button>
        </form>
        <Alert show={Object.keys(mailSuccesses).length > 0} variant="success">
          <p>{mailSuccesses.general}</p>
        </Alert>
        <Alert show={errors.general ? true : false} variant="danger">
          <p>{errors.general}</p>
        </Alert>
      </Col>
    </Row>
  );
}

export default ContactForm;
