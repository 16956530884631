import React, { useEffect } from "react";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import { checkAnim } from "../util/animationTrigger";

import aeec from "../assets/workplaces/aeec-logo.png";
import sdsc from "../assets/workplaces/sdsc-logo.png";
import ledgersafe from "../assets/workplaces/ledgersafe-logo.png";
import btg from "../assets/workplaces/btg-logo.png";
import solace from "../assets/workplaces/solace-2-logo.svg";
import unlonely from "../assets/workplaces/unlonely-logo.png";

import resume from "../assets/DannyFungResume.pdf";

import js from "../assets/skills/languages/js-logo.png";
import ts from "../assets/skills/languages/ts-logo.png";
import html from "../assets/skills/languages/html-logo.png";
import css from "../assets/skills/languages/css-logo.png";
import sass from "../assets/skills/languages/sass-logo.png";
import sol from "../assets/skills/languages/solidity-logo.png";
import py from "../assets/skills/languages/py-logo.png";
import sql from "../assets/skills/languages/sql-logo.png";
import csharp from "../assets/skills/languages/csharp-logo.png";
import c from "../assets/skills/languages/c-logo.png";
import java from "../assets/skills/languages/java-logo.png";
import cpp from "../assets/skills/languages/cpp-logo.png";

import node from "../assets/skills/frameworks/node-logo.png";
import express from "../assets/skills/frameworks/express-logo.png";
import react from "../assets/skills/frameworks/react-logo.png";
import redux from "../assets/skills/frameworks/redux-logo.png";
import gatsby from "../assets/skills/frameworks/gatsby-logo.png";
import jest from "../assets/skills/frameworks/jest-logo.png";
import jquery from "../assets/skills/frameworks/jquery-logo.png";
import bootstrap from "../assets/skills/frameworks/bootstrap-logo.png";
import bulma from "../assets/skills/frameworks/bulma-logo.png";
import mui from "../assets/skills/frameworks/mui-logo.png";
import truffle from "../assets/skills/frameworks/truffle-logo.png";
import web3 from "../assets/skills/frameworks/web3-logo.png";
import vue from "../assets/skills/frameworks/vue-logo.png";
import ethers from "../assets/skills/frameworks/ethers-logo.png";
import styled_components from "../assets/skills/frameworks/styled-components-logo.png";
import tailwind from "../assets/skills/frameworks/tailwind-logo.png";
import nextjs from "../assets/skills/frameworks/nextjs-logo.png";
import chakra_ui from "../assets/skills/frameworks/chakra-ui-logo.png";
import privy from "../assets/skills/frameworks/privy-logo.png";

import git from "../assets/skills/devTools/git-logo.png";
import npm from "../assets/skills/devTools/npm-logo.png";
import vscode from "../assets/skills/devTools/vscode-logo.png";
import mongo from "../assets/skills/devTools/mongo-logo.png";
import mysql from "../assets/skills/devTools/mysql-logo.png";
import docker from "../assets/skills/devTools/docker-logo.png";
import ganache from "../assets/skills/devTools/ganache-logo.png";
import postman from "../assets/skills/devTools/postman-logo.png";
import heroku from "../assets/skills/devTools/heroku-logo.png";
import netlify from "../assets/skills/devTools/netlify-logo.png";
import firebase from "../assets/skills/devTools/firebase-logo.png";
import gcp from "../assets/skills/devTools/gcp-logo.png";
import infura from "../assets/skills/devTools/infura-logo.png";
import alchemy from "../assets/skills/devTools/alchemy-logo.png";
import graphql from "../assets/skills/devTools/graphql-logo.png";
import aws from "../assets/skills/devTools/aws-logo.png";
import vercel from "../assets/skills/devTools/vercel-logo.png";
import digitalocean from "../assets/skills/devTools/digitalocean-logo.png";

import "./ExperienceSection.css";
import TooltipComponent from "../components/TooltipComponent";

function ExperienceSection() {
  var companies;
  var angle = 0;
  var resumeContainer;

  useEffect(() => {
    companies = document.getElementsByClassName("company");
    resumeContainer = document.getElementById("resume-bg");
  }, []);

  document.addEventListener("scroll", function (e) {
    checkAnim("experience", "fadeIn");
    let expList = document.getElementById("experience");
    let scrollPos = window.scrollY;
    let elementPos =
      window.pageYOffset +
      expList.getBoundingClientRect().top +
      expList.offsetHeight / 4;
    if (scrollPos + window.innerHeight > elementPos) {
      for (let i = 0; i < companies.length; i++) {
        if (!companies[i].classList.contains("anim-done")) {
          companies[i].classList.add("anim-done");
          companies[i].animate(
            [
              { opacity: 0, transform: "translateY(50px)" },
              { opacity: 1, transform: "translateY(0)" },
            ],
            {
              duration: 400,
              delay: 200 * i + 200,
              fill: "forwards",
            }
          );
        }
      }
    }
  });

  return (
    <section id="experience" className="section-container opacity-zero">
      <Container fluid>
        <Row className="experience-info">
          <Col className="col-container">
            <div className="text">
              <h2 className="section-title">Experience</h2>
              <h5 className="subtitle assisting-text">
                These are the organizations that I have worked with:
              </h5>
            </div>
            <div className="work-columns">
              <Row>
                <Col className="company opacity-zero">
                  <img
                    src={unlonely}
                    alt="Unlonely"
                    style={{ width: "180px", height: "180px" }}
                  />
                  <h5 className="assisting-text">Unlonely</h5>
                  <div className="work-info">
                    <h5 className="assisting-text">Full Stack Developer</h5>
                    <h6 className="peripheral-text">May 2023 - Nov 2024</h6>
                    <h6 className="assisting-text padding-top">Tech Stack:</h6>
                    <Row className="skill-list">
                      <TooltipComponent text="React">
                        <img className="tech-block" src={react} />
                      </TooltipComponent>
                      <TooltipComponent text="NextJs">
                        <img className="tech-block" src={nextjs} />
                      </TooltipComponent>
                      <TooltipComponent text="Ethers.js">
                        <img className="tech-block" src={ethers} />
                      </TooltipComponent>
                      <TooltipComponent text="Alchemy">
                        <img className="tech-block" src={alchemy} />
                      </TooltipComponent>
                      <TooltipComponent text="GraphQL">
                        <img className="tech-block" src={graphql} />
                      </TooltipComponent>
                      <TooltipComponent text="TypeScript">
                        <img className="tech-block" src={ts} />
                      </TooltipComponent>
                      <TooltipComponent text="JavaScript">
                        <img className="tech-block" src={js} />
                      </TooltipComponent>
                      <TooltipComponent text="Amazon Web Services">
                        <img className="tech-block" src={aws} />
                      </TooltipComponent>
                      <TooltipComponent text="Vercel">
                        <img className="tech-block" src={vercel} />
                      </TooltipComponent>
                      <TooltipComponent text="DigitalOcean">
                        <img className="tech-block" src={digitalocean} />
                      </TooltipComponent>
                      <TooltipComponent text="Chakra UI">
                        <img className="tech-block" src={chakra_ui} />
                      </TooltipComponent>
                      <TooltipComponent text="Privy">
                        <img className="tech-block" src={privy} />
                      </TooltipComponent>
                    </Row>
                    <Accordion className="accordion">
                      <Card className="accordion-card">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          className="accordion-header"
                        >
                          Learn more
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey="0"
                          className="accordion-body"
                        >
                          <Card.Body className="accordion-body-card">
                            <ul>
                              <li>
                                <h6>
                                  Reworked and implemented multiple systems in
                                  the existing web app using an open-minded
                                  development approach.
                                </h6>
                              </li>
                              <li>
                                <h6>
                                  Spearheaded periodic reviews on user flow,
                                  quality of the product, and designs.
                                </h6>
                              </li>
                              <li>
                                <h6>
                                  Provided extensive recommendations and
                                  feedback to smart contract drafts to ensure
                                  durability and bug-free experience.
                                </h6>
                              </li>
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </Col>
                <Col className="company opacity-zero">
                  <img
                    src={solace}
                    alt="Solace"
                    style={{ width: "180px", height: "180px" }}
                  />
                  <h5 className="assisting-text">Solace</h5>
                  <div className="work-info">
                    <h5 className="assisting-text">Frontend Developer</h5>
                    <h6 className="peripheral-text">March 2021 - May 2023</h6>
                    <h6 className="assisting-text padding-top">Tech Stack:</h6>
                    <Row className="skill-list">
                      <TooltipComponent text="React">
                        <img className="tech-block" src={react} />
                      </TooltipComponent>
                      <TooltipComponent text="Vue">
                        <img className="tech-block" src={vue} />
                      </TooltipComponent>
                      <TooltipComponent text="NextJs">
                        <img className="tech-block" src={nextjs} />
                      </TooltipComponent>
                      <TooltipComponent text="Ethers.js">
                        <img className="tech-block" src={ethers} />
                      </TooltipComponent>
                      <TooltipComponent text="Styled Components">
                        <img className="tech-block" src={styled_components} />
                      </TooltipComponent>
                      <TooltipComponent text="Tailwind">
                        <img className="tech-block" src={tailwind} />
                      </TooltipComponent>
                      <TooltipComponent text="Alchemy">
                        <img className="tech-block" src={alchemy} />
                      </TooltipComponent>
                      <TooltipComponent text="GraphQL">
                        <img className="tech-block" src={graphql} />
                      </TooltipComponent>
                      <TooltipComponent text="TypeScript">
                        <img className="tech-block" src={ts} />
                      </TooltipComponent>
                      <TooltipComponent text="JavaScript">
                        <img className="tech-block" src={js} />
                      </TooltipComponent>
                      <TooltipComponent text="Amazon Web Services">
                        <img className="tech-block" src={aws} />
                      </TooltipComponent>
                    </Row>
                    <Accordion className="accordion">
                      <Card className="accordion-card">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          className="accordion-header"
                        >
                          Learn more
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey="0"
                          className="accordion-body"
                        >
                          <Card.Body className="accordion-body-card">
                            <ul>
                              <li>
                                <h6>
                                  Shipped reusable, quality UI code and ensured
                                  careful version control for multiple products.
                                </h6>
                              </li>
                              <li>
                                <h6>
                                  Refactored and constantly optimized
                                  functionality to improve user experience upon
                                  feedback from management and designer teams.
                                </h6>
                              </li>
                              <li>
                                <h6>
                                  Continuously researched new tools for
                                  development and iterated on prototype designs
                                  for new features every launch since the first
                                  on July 2021.
                                </h6>
                              </li>
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </Col>
                <Col className="company opacity-zero">
                  <img src={sdsc} alt="San Diego Supercomputer Center" />
                  <h5 className="assisting-text">
                    San Diego Supercomputer Center
                  </h5>
                  <div className="work-info">
                    <h5 className="assisting-text">Software Engineer</h5>
                    <h6 className="peripheral-text">Jul 2019 - Sep 2020</h6>
                    <h6 className="assisting-text padding-top">Tech Stack:</h6>
                    <Row className="skill-list">
                      <TooltipComponent text="React">
                        <img className="tech-block" src={react} />
                      </TooltipComponent>
                      <TooltipComponent text="Node.js">
                        <img className="tech-block" src={node} />
                      </TooltipComponent>
                      <TooltipComponent text="Express">
                        <img className="tech-block" src={express} />
                      </TooltipComponent>
                      <TooltipComponent text="Bootstrap">
                        <img className="tech-block" src={bootstrap} />
                      </TooltipComponent>
                      <TooltipComponent text="Solidity">
                        <img className="tech-block" src={sol} />
                      </TooltipComponent>
                      <TooltipComponent text="Ganache">
                        <img className="tech-block" src={ganache} />
                      </TooltipComponent>
                      <TooltipComponent text="Truffle">
                        <img className="tech-block" src={truffle} />
                      </TooltipComponent>
                      <TooltipComponent text="Web3.js">
                        <img className="tech-block" src={web3} />
                      </TooltipComponent>
                    </Row>
                    <Accordion className="accordion">
                      <Card className="accordion-card">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          className="accordion-header"
                        >
                          Learn more
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey="0"
                          className="accordion-body"
                        >
                          <Card.Body className="accordion-body-card">
                            <ul>
                              <li>
                                <h6>
                                  Developed a blockchain-based web application
                                  prototype using React, node.js, and Solidity,
                                  that tracks claims filed by healthcare
                                  providers and services provided to patients.
                                </h6>
                              </li>
                              <li>
                                <h6>
                                  Integrated tracking functionality of 4
                                  Ethereum smart contracts, documented technical
                                  designs and frameworks, and implemented
                                  back-end layers on node.js and Solidity.
                                </h6>
                              </li>
                              <li>
                                <h6>
                                  Tested compatibility of conventional web tools
                                  with current blockchain infrastructure and
                                  existing distributed systems.
                                </h6>
                              </li>
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="company opacity-zero">
                  <img src={aeec} alt="AEEC, LLC" />
                  <h5 className="assisting-text">AEEC, LLC</h5>
                  <div className="work-info">
                    <h5 className="assisting-text">Software Engineer</h5>
                    <h6 className="peripheral-text">Jun 2020 - Sep 2020</h6>
                    <h6 className="assisting-text padding-top">Tech Stack:</h6>
                    <Row className="skill-list">
                      <TooltipComponent text="Python">
                        <img className="tech-block" src={py} />
                      </TooltipComponent>
                      <TooltipComponent text="Google Cloud Platform">
                        <img className="tech-block" src={gcp} />
                      </TooltipComponent>
                      <TooltipComponent text="Node">
                        <img className="tech-block" src={node} />
                      </TooltipComponent>
                    </Row>
                    <Accordion className="accordion">
                      <Card className="accordion-card">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          className="accordion-header"
                        >
                          Learn more
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey="0"
                          className="accordion-body"
                        >
                          <Card.Body className="accordion-body-card">
                            <ul>
                              <li>
                                <h6>
                                  Collaborated on the user interface design for
                                  data science notebooks and databases from
                                  business clients.
                                </h6>
                              </li>
                              <li>
                                <h6>
                                  Trained on using cloud technology, including
                                  Azure and GCP, to help progress projects
                                  forward and earned certificates for completing
                                  relevant courses.
                                </h6>
                              </li>
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </Col>
                <Col className="company opacity-zero">
                  <img src={ledgersafe} alt="LedgerSafe" />
                  <h5 className="assisting-text">LedgerSafe</h5>
                  <div className="work-info">
                    <h5 className="assisting-text">
                      Front-end Developer Intern
                    </h5>
                    <h6 className="peripheral-text">May 2019 - Sep 2019</h6>
                    <h6 className="assisting-text padding-top">Tech Stack:</h6>
                    <Row className="skill-list">
                      <TooltipComponent text="React">
                        <img className="tech-block" src={react} />
                      </TooltipComponent>
                      <TooltipComponent text="Node">
                        <img className="tech-block" src={node} />
                      </TooltipComponent>
                      <TooltipComponent text="Express">
                        <img className="tech-block" src={express} />
                      </TooltipComponent>
                      <TooltipComponent text="Bootstrap">
                        <img className="tech-block" src={bootstrap} />
                      </TooltipComponent>
                    </Row>
                    <Accordion className="accordion">
                      <Card className="accordion-card">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          className="accordion-header"
                        >
                          Learn more
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey="0"
                          className="accordion-body"
                        >
                          <Card.Body className="accordion-body-card">
                            <ul>
                              <li>
                                <h6>
                                  Collaborated on a React web application
                                  designed to lower friction between high risk
                                  businesses and financial institutions through
                                  establishing trust using distributed ledger
                                  technology.
                                </h6>
                              </li>
                              <li>
                                <h6>
                                  Studied application of Hyperledger Fabric, a
                                  permissioned blockchain infrastructure, and
                                  role of compliance frameworks in distributed
                                  systems.
                                </h6>
                              </li>
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </Col>
                <Col className="company opacity-zero">
                  <img src={btg} alt="Blockchain Technology Group, LLC" />
                  <h5 className="assisting-text">
                    Blockchain Technology Group, LLC
                  </h5>
                  <div className="work-info">
                    <h5 className="assisting-text">
                      Software Engineering Intern
                    </h5>
                    <h6 className="peripheral-text">Oct 2018 - Dec 2018</h6>
                    <h6 className="assisting-text padding-top">Tech Stack:</h6>
                    <Row className="skill-list">
                      <TooltipComponent text="React">
                        <img className="tech-block" src={react} />
                      </TooltipComponent>
                    </Row>
                    <Accordion className="accordion">
                      <Card className="accordion-card">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          className="accordion-header"
                        >
                          Learn more
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey="0"
                          className="accordion-body"
                        >
                          <Card.Body className="accordion-body-card">
                            <ul>
                              <li>
                                <h6>
                                  Designed a React user interface of a
                                  cryptocurrency wallet to track balance of
                                  Florincoin tokens and transaction histories.
                                </h6>
                              </li>
                              <li>
                                <h6>
                                  Studied Open Index Protocol technology, the
                                  core intricacies of blockchain, and its
                                  economic principles.
                                </h6>
                              </li>
                              <li>
                                <h6>
                                  Wrote final paper surrounding the current
                                  proof of concept and practice of distributed
                                  ledger technology.
                                </h6>
                              </li>
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid id="resume-bg" className="resume-bg">
        <Col className="resume-wrapper">
          <h5 className="subtitle" style={{ color: "white" }}>
            For more details on my experience, see my résumé!
          </h5>
          <a href={resume}>
            <button className="highlight-button highlight-white">
              View Résumé
            </button>
          </a>
        </Col>
      </Container>
    </section>
  );
}

export default ExperienceSection;
