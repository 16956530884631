import "./App.css";
// import { Container } from "react-bootstrap";
import Navbar from './components/Navbar';
import HeroSection from "./sections/HeroSection";
import BioSection from "./sections/BioSection";
import SkillSection from "./sections/SkillSection";
import ExperienceSection from "./sections/ExperienceSection";
import ProjectSection from "./sections/ProjectSection";
import ContactSection from "./sections/ContactSection";

function App() {
  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <BioSection />
      <SkillSection />
      <ExperienceSection />
      <ProjectSection />
      <ContactSection />
    </div>
  );
}

export default App;
