import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { checkAnim } from "../util/animationTrigger";
import danny from "../assets/danny-circle.png";
import "./BioSection.css";

function BioSection() {
  document.addEventListener("scroll", function (e) {
    checkAnim("about", "fadeIn");
    let bio = document.getElementById("about");
    let scrollPos = window.scrollY;
    let elementPos =
      window.pageYOffset +
      bio.getBoundingClientRect().top +
      bio.offsetHeight / 4;
    if (scrollPos + window.innerHeight > elementPos) {
      let descriptions = bio.getElementsByClassName("subtitle");
      for (let i = 0; i < descriptions.length; i++) {
        if (i % 2 === 0) {
          slideFromLeft(descriptions[i], i);
        } else {
          slideFromRight(descriptions[i], i);
        }
      }
    }
  });

  function slideFromRight(el, i) {
    if (!el.classList.contains("anim-done")) {
      el.classList.add("anim-done");
      el.animate(
        [
          { opacity: 0, transform: "translateX(50%)" },
          { opacity: 1, transform: "translateX(0%)" },
        ],
        {
          duration: 700,
          delay: 200,
          easing: "ease",
          fill: "forwards",
        }
      );
    }
  }

  function slideFromLeft(el, i) {
    if (!el.classList.contains("anim-done")) {
      el.classList.add("anim-done");
      el.animate(
        [
          { opacity: 0, transform: "translateX(-50%)" },
          { opacity: 1, transform: "translateX(0%)" },
        ],
        {
          duration: 700,
          delay: 200,
          easing: "ease",
          fill: "forwards",
        }
      );
    }
  }

  return (
    <section className="gradient-bg">
      <div className="clipped-gradient-bg">
        <Container
          id="about"
          fluid
          className="bio section-container opacity-zero"
        >
          <Col md={{ span: 6, offset: 3 }} className="col-container">
            <div className="text">
              <h2 className="section-title">About Me</h2>
              <div className="bio-image">
                <img src={danny} />
              </div>
              <h4 className="subtitle opacity-zero">Hello, my name is Danny Fung!</h4>
              <h5 className="subtitle opacity-zero">
                I'm a full stack web developer with a background in blockchain
                development. I've worked with multiple small organizations to create responsive user
                interfaces and modern web applications.
              </h5>
              <h5 className="subtitle opacity-zero">
                As an aspiring developer, I want to create software that could define the technology of tomorrow.
                I also enjoy learning about blockchain and I am always looking for opportunities where I can work on it.
              </h5>
              <h5 className="subtitle opacity-zero">
                My ultimate goal is to build applications with streamlined systems and smooth user experience.
              </h5>
            </div>
          </Col>
        </Container>
      </div>
    </section>
  );
}

export default BioSection;
