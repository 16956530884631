import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { checkAnim } from "../util/animationTrigger";
import ContactForm from "../components/ContactForm";

import "./ContactSection.css";

function ContactSection() {
  // document.addEventListener("scroll", function (e) {
  //   checkAnim("contact", "fadeIn");
  // });

  return (
    <section id="contact" className="contact-section-container">
      <Container fluid>
        <Row>
          <Col md={{ span: 8, offset: 2 }} className="col-container">
            <div className="text">
              <div>
                <span className="icon">
                  <i className="fas fa-envelope fa-lg big-icon"></i>
                </span>
                <div className="contact-text">
                  <h2 className="section-title">Looking to work together?</h2>
                  <h3>Let's get connected.</h3>
                </div>
                <ContactForm />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className='contact-bg'>
        <Row>
          <Col md={{ span: 8, offset: 2 }} className="col-container">
            <div className="lower-contact">
              <Row className="contact-options">
                <a href="mailto:dannyfung97@gmail.com">
                  <button className="button contact-button">
                    <span className="icon">
                      <i className="fas fa-envelope fa-lg"></i>
                    </span>
                  </button>
                </a>
                <a href="https://github.com/DannyFung97" target="_blank">
                  <button className="button contact-button">
                    <span className="icon">
                      <i className="fab fa-github fa-lg"></i>
                    </span>
                  </button>
                </a>
                <a href="https://www.linkedin.com/in/danny-fung/" target="_blank">
                  <button className="button contact-button">
                    <span className="icon">
                      <i className="fab fa-linkedin fa-lg"></i>
                    </span>
                  </button>
                </a>
              </Row>
              <h6 className="">
                Made by Danny Fung using React{" "}
                <span className="icon">
                  <i className="fas fa-copyright fa-sm"></i>
                </span>{" "}
                2021
              </h6>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ContactSection;
