import React, { useState } from "react";
import { Modal, Row } from "react-bootstrap";
import "./Project.css";
import Carousel from "react-bootstrap/Carousel";
import TooltipComponent from "./TooltipComponent";

function Project({ project }) {
  const [show, setShow] = useState(false);

  return (
    <div className="project-wrapper">
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header className="modal-edge">
          <Modal.Title className="modal-title">{project.name}</Modal.Title>
          <button
            type="button"
            className="close x-button"
            onClick={() => setShow(false)}
          >
            <span aria-hidden="true">x</span>
            <span className="sr-only">close</span>
          </button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          {project.imgs.length > 1 ? (
            <Carousel slide>
              {project.imgs.map((img, i) => (
                <Carousel.Item key={i} interval={5000}>
                  <img className="project-img" src={img} />
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <img className="project-img" src={project.imgs[0]} />
          )}
          <h6 className="peripheral-text padding-top">Technologies used:</h6>
          <Row className="tech-list">
            {project.stack.map((tech, i) => (
              <TooltipComponent text={project.stackNames[i]} key={i}>
                <img className="tech-block" src={tech} />
              </TooltipComponent>
            ))}
          </Row>
          {project.info}
          <div className="project-buttons">
            {project.liveUrl === project.codeUrl ? (
              <button className="disabled-button">Developing</button>
            ) : project.liveUrl === null ? null : (
              <a href={project.liveUrl}>
                <button className="button project-button">View App</button>
              </a>
            )}
            <a href={project.codeUrl}>
              <button className="button project-button">View Code</button>
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-edge">
          <button
            className="button close-button"
            onClick={() => setShow(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="project" onClick={() => setShow(true)}>
        <div className="project-overlay">
          <div className="project-overlay-info">
            <h4 className="project-name">{project.name}</h4>
          </div>
        </div>
        <img className="project-img" src={project.imgs[0]} />
      </div>
    </div>
  );
}

export default Project;
